<template>
  <section class="detalhe-apolice">
    <div class="m-1 mr-4 px-0 mb-5">
      <breadcrumb
        title="Apólice"
        actualPage="Detalhes da apólice"
        previousPage="apolices"
        previousPageTitle="Lista de apólices"
        class="mb-6"
      />
    </div>
    <div
      class="detalhe-apolice new-tabs"
      style="  position: relative; margin-top:80px"
    >
      <div
        v-if="policy.PolicyStatusId == 2 && PerfilHelpers.isInsurance()"
        :class="policy.IsBlockRenewal ? 'blocked' : 'unblock'"
      >
        <div class="icon">
          <i class="el-icon-warning-outline"></i>
        </div>
        <div class="text">
          <b>
            {{
              policy.IsBlockRenewal
                ? "Renovação  bloqueada "
                : "Renovação Ativa"
            }}</b
          >
          <p>
            {{
              policy.IsBlockRenewal
                ? "Renovação automática da apólice está bloqueada."
                : "Renovação automática da apólice está Ativa."
            }}
          </p>
        </div>
        <div class="button ml-3">
          <el-button
            @click="disclamerdesabilitarRenovacao(policy)"
            id="blocked"
            round
            style="    padding: 5px 17px;"
          >
            {{ policy.IsBlockRenewal ? "Desbloquear" : "Bloquear" }}</el-button
          >
        </div>
      </div>
      <vs-tabs color="warning" v-if="policy.PolicyUniqueId">
        <vs-tab label="Dados gerais">
          <detalhar-apolice :policy="policy" />
        </vs-tab>

        <vs-tab label="Histórico">
          <historico-apolice :history="policy.PolicyHistory" />
        </vs-tab>

        <vs-tab label="Endosso(s)" v-if="policy.HasEndorsement">
          <endosso-list :policyUniqueId="policy.PolicyUniqueId" />
        </vs-tab>
      </vs-tabs>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb";
import DetalharApolice from "./components/DetalharApolice.vue";
import HistoricoApolice from "./components/HistoricoApolice.vue";
import EndossoList from "./components/EndossoList.vue";
import perfilHelpers from "@/helpers/PerfilHelpers";

export default {
  name: "detalhar-apolices",
  components: {
    Breadcrumb,
    DetalharApolice,
    HistoricoApolice,
    EndossoList
  },
  data() {
    return {
      policyUniqueId: this.$route.params.policyUniqueId,
      policy: Object,
      PerfilHelpers: perfilHelpers
    };
  },
  created() {
    this.$onpoint.loading(() => {
      return this.loadApolicesHistory(this.policyUniqueId).then(
        resp => (this.policy = resp)
      );
    });
    this.$appInsights.trackPageView({
      name: "detalhar-apolice",
      url: window.location.href
    });
  },
  methods: {
    ...mapActions("apolice-module", [
      "loadApolicesHistory",
      "disableAndEnableRenovacao",
      "getListEndorsementPolicy"
    ]),
    async loadPolicy() {
       return await  this.loadApolicesHistory(this.policyUniqueId).then(
        resp => (this.policy = resp)
      );
    },
    async disclamerdesabilitarRenovacao(data) {
      await this.$confirm(
        "Tem certeza que deseja realizar essa  ação?",
        "Atenção!",
        {
          confirmButtonText: "Continuar",
          cancelButtonText: "Voltar"
        }
      ).then(async () => {
        await this.desabilitarRenovacaoAction(data);
      });
    },
    async desabilitarRenovacaoAction(data) {
      await this.$onpoint.loading(async () => {
        try {
          await this.desabilitarRenovacao(data);
        } catch (error) {
          this.$onpoint.errorModal(error.response.data.Errors);
        } finally {
           await this.loadPolicy();
        }
      });
    },
    async desabilitarRenovacao(data) {
      let payload = {
        PolicyUniqueId: data.PolicyUniqueId,
        IsBlockRenewal: !data.IsBlockRenewal
      };
      await this.$onpoint.loading(async () => {
        return await this.disableAndEnableRenovacao(payload)
          .then(async () => {
            this.$notify({
              title: "Success",
              message: "Dados atualizados com sucesso!",
              type: "success"
            });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    }
  }
};
</script>

<style lang="scss">
.perfil-corretora-tabs {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.detalhe-apolice {
  .con-vs-tabs {
    display: flex;
  }
  .con-ul-tabs {
    background: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 250px;
    padding: 50px 20px;
    margin-bottom: 10px;
  }
  .line-vs-tabs {
    margin-top: 50px;
    margin-left: 20px;
  }
  .vs-tabs--li button.vs-tabs--btn {
    margin-top: 30px;
    width: auto;
  }
  .vs-tabs--ul {
    box-shadow: none;
    flex-direction: column;
  }
  .vs-tabs--li {
    padding-right: 15px;
  }
  .con-slot-tabs {
    background: white;
    border-radius: 10px;
    margin: 0 20px 10px 5px;
  }
}
.unblock {
  font-size: 14px !important;
  position: absolute;
  right: 20px;
  top: -80px;
  border-radius: 15px;
  background-color: rgb(158, 248, 185) !important;
  border: 1px solid rgb(0, 214, 64) !important;
  padding: 12px 15px;
  display: flex;
  .icon {
    font-size: 27px;
    margin-right: 8px;
  }
  .button {
    margin-left: 5px;
    display: flex;
    align-content: center;
    align-items: center;
  }
}
.blocked {
  font-size: 14px !important;
  position: absolute;
  right: 20px;
  top: -80px;
  border-radius: 15px;
  background-color: rgb(252, 219, 219);
  border: 1px solid rgb(155, 9, 9);
  padding: 12px 15px;
  display: flex;
  .icon {
    font-size: 27px;
    margin-right: 8px;
  }
  .button {
    margin-left: 5px;
    display: flex;
    align-content: center;
    align-items: center;
  }
}
</style>
