var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          staticClass: "table-list",
          attrs: {
            noDataText: "Nenhum endosso encontrado",
            data: _vm.endossoList || [],
            hoverFlat: true,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ data }) {
                return _vm._l(data, function (item) {
                  return _c(
                    "vs-tr",
                    { key: item.Number, attrs: { data: item } },
                    [
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center font-semibold",
                          attrs: { data: item.Number },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.Number) + "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center",
                          attrs: { data: item.Proposal.Number },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                item.ProposalType.Id == 3
                                  ? "NA"
                                  : item.Proposal.Number
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center",
                          attrs: { data: item.IssueDate },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$utils.dateToDDMMYYYY(item.IssueDate) ==
                                  "01/01/0001"
                                  ? "NA"
                                  : _vm.$utils.dateToDDMMYYYY(item.IssueDate)
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center",
                          attrs: { data: item.Proposal.StartDate },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                item.Proposal.StartDate
                                  ? _vm.$utils.dateToDDMMYYYY(
                                      item.Proposal.StartDate
                                    )
                                  : "-"
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center",
                          attrs: { data: item.Proposal.EndDate },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                item.Proposal.EndDate
                                  ? _vm.$utils.dateToDDMMYYYY(
                                      item.Proposal.EndDate
                                    )
                                  : "-"
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center",
                          attrs: { data: item.EndorsementType.Name },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(item.EndorsementType.Name) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center w-1/12",
                          attrs: { data: item.Proposal.InsuredAmountValue },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                item.ProposalType.Id == 3
                                  ? "NA"
                                  : _vm.$utils.formatStrMoney(
                                      item.Proposal.InsuredAmountValue,
                                      2,
                                      ",",
                                      "."
                                    )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center w-1/12",
                          attrs: { data: item.Proposal.InsurancePremium },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                item.ProposalType.Id == 3
                                  ? "NA"
                                  : _vm.$utils.formatStrMoney(
                                      item.Proposal.InsurancePremium,
                                      2,
                                      ",",
                                      "."
                                    )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        {
                          staticClass: "text-center",
                          attrs: { data: item.Status.Name },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                item.ProposalType.Id == 3
                                  ? "NA"
                                  : item.Status.Name
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _c(
                        "vs-td",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "vs-dropdown",
                            {
                              staticClass: "btn-action-apolice",
                              attrs: {
                                "vs-custom-content": "",
                                "vs-trigger-click": "",
                                disabled:
                                  item.ProposalType.Id == 3 ? true : false,
                              },
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  attrs: {
                                    color: "dark",
                                    type: "flat",
                                    title: "Ações",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "onpoint-dots-three-vertical icon-dots-three-vertical",
                                    attrs: { role: "button" },
                                  }),
                                ]
                              ),
                              _c(
                                "vs-dropdown-menu",
                                { staticClass: "btn-action-apolice-options" },
                                [
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.loadDownloadEndorsementDocument(
                                            item.Proposal.UniqueId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "text-base px-6 py-2" },
                                        [_vm._v("Download endosso")]
                                      ),
                                    ]
                                  ),
                                  item.Proposal.InsurancePremium > 0
                                    ? _c(
                                        "vs-dropdown-item",
                                        {
                                          attrs: { divider: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showModalParcelas(
                                                item.Proposal.UniqueId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-base px-6 py-2",
                                            },
                                            [_vm._v("Download do(s) boleto(s)")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      attrs: { divider: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadLaudoEmissaoClick(
                                            item.Proposal.UniqueId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "text-base px-6 py-2" },
                                        [
                                          _vm._v(
                                            "\n                  Download do laudo de emissão\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  item.PolicyNumber != null &&
                                  item.AttachmentCount &&
                                  item.AttachmentCount > 0
                                    ? _c(
                                        "vs-dropdown-item",
                                        {
                                          attrs: { divider: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showEndorsementAttachmentsModal(
                                                item.EndorsementId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-base px-6 py-2",
                                            },
                                            [_vm._v("Download do(s) anexo(s)")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                })
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { staticClass: "text-center" }, [
                _vm._v("Nº endosso"),
              ]),
              _c("vs-th", { staticClass: "text-center" }, [
                _vm._v("Proposta do endosso"),
              ]),
              _c("vs-th", { staticClass: "text-center" }, [
                _vm._v("Dt. emissão"),
              ]),
              _c("vs-th", { staticClass: "text-center" }, [
                _vm._v("Início da vigência"),
              ]),
              _c("vs-th", { staticClass: "text-center" }, [
                _vm._v("Fim da vigência"),
              ]),
              _c("vs-th", { staticClass: "text-center" }, [
                _vm._v("Tipo do endosso"),
              ]),
              _c("vs-th", { staticClass: "text-center" }, [_vm._v("IS")]),
              _c("vs-th", { staticClass: "text-center" }, [_vm._v("Prêmio")]),
              _c("vs-th", { staticClass: "text-center" }, [_vm._v("Situação")]),
              _c("vs-th", { staticClass: "text-center" }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            id: "listar-documentos-endosso-modal",
            title: " ",
            active: _vm.endossoAnexosModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.endossoAnexosModal = $event
            },
          },
        },
        [
          _c("h2", { staticClass: "text-primary" }, [
            _vm._v("Documentos do endosso"),
          ]),
          _c(
            "vs-table",
            {
              staticClass: "table-list mt-5",
              attrs: {
                maxHeight: "600px",
                data: _vm.endossoAnexos,
                maxItems: _vm.endossoAnexos.length,
                hoverFlat: true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (item, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: item } },
                        [
                          _c("vs-td", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.Name) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.StatusName) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$utils.dateToDDMMYYYY(item.UpdateDate)
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.IsRequired ? "Sim" : "Não") +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { staticClass: "text-center" }, [
                            _c(
                              "span",
                              { staticClass: "flex" },
                              [
                                item.TotalArchives > 0
                                  ? _c("vs-button", {
                                      staticClass: "visualizar-anexos",
                                      attrs: {
                                        radius: "",
                                        color: "primary",
                                        type: "flat",
                                        icon: "get_app",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadEndorsementAttachment(
                                            item.UniqueId
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v(" Título "),
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v(" Status "),
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v(" Data Upload "),
                  ]),
                  _c("vs-th", { staticClass: "text-center" }, [
                    _vm._v(" Obrigatório "),
                  ]),
                  _c("vs-th"),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            id: "listar-anexos-documentos-endosso-modal",
            title: " ",
            active: _vm.endossoAnexosArquivosModal,
          },
          on: {
            "update:active": function ($event) {
              _vm.endossoAnexosArquivosModal = $event
            },
          },
        },
        [
          _c("h2", { staticClass: "text-primary" }, [
            _vm._v("Arquivos do anexo"),
          ]),
          _c(
            "vs-table",
            {
              staticClass: "table-list mt-5",
              attrs: {
                maxHeight: "600px",
                data: _vm.endossoAnexosArquivos,
                maxItems: _vm.endossoAnexosArquivos.length,
                hoverFlat: true,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ data }) {
                    return _vm._l(data, function (item, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: item } },
                        [
                          _c("vs-td", { staticClass: "text-left" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.OriginalFilename + item.Extension) +
                                "\n          "
                            ),
                          ]),
                          _c("vs-td", { staticClass: "w-1/12" }, [
                            _c(
                              "span",
                              { staticClass: "flex" },
                              [
                                _c("vs-button", {
                                  staticClass: "visualizar-anexos",
                                  attrs: {
                                    radius: "",
                                    color: "primary",
                                    type: "flat",
                                    icon: "get_app",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.downloadAttachmentFile(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    })
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { staticClass: "text-left" }, [_vm._v(" Nome ")]),
                  _c("vs-th"),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      !!_vm.boleto.uniqueId
        ? _c("listar-parcelas-popup", {
            attrs: {
              parcelasModal: _vm.boleto.showModal,
              propostaUniqueId: _vm.boleto.uniqueId,
              autoLoad: true,
            },
            on: {
              fecharParcelasModal: function ($event) {
                _vm.boleto = { showModal: false }
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }